import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    height: 590px;
  }

  .logo-inter {
    width: 87px;

    @media ${device.desktopLG} {
      width: 116px;
    }
  }

  @media ${device.desktopLG} {
    height: 588px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/background-hero-financas-na-pratica-lg/image.webp');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 100%;
  }

  @media ${device.desktopXL} {
    height: 640px;
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/background-hero-financas-na-pratica-xl/image.webp');
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 100% 100%;
  }

  @media ${device.desktopXXL} {
    background-size: cover;
  }

  h1 {
    font-size: 32px;
    font-family: 'Citrina';
    line-height: 40px;

    @media ${device.tablet} {
      font-size: 40px;
      line-height: 45px;
    }

    @media ${device.desktopLG} {
      font-size: 40px;
      line-height: 40px;
    }

    @media ${device.desktopXL} {
      font-size: 48px;
      line-height: 50px;
    }
  }

  .content-info{
    max-width: 410px;
    img{
      max-width:160px;
    }
  }

`
