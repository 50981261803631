import React, { useState } from 'react'

import DefaultCarousel from 'src/components/UI/Carousels/DefaultCarousel'
import ScrollTo from 'src/components/ScrollTo'

import { Section, Carousel } from './style'

import OqueVoceVaiAprenderJSON from './data/OqueVoceVaiAprender.json'

type OqueVoceVaiAprenderProps = {
  image: string;
  image_alt: string;
  title: string;
  description: string;
}

const OqueVoceVaiAprender = () => {
  const [ isActive, setIsActive ] = useState(false)
  const [ isIndex, setIsIndex ] = useState(0)

  const handleClick = (index: number) => {
    setIsIndex(index)
  }

  return (
    <Section className='py-5 d-flex align-items-center'>
      <div className='container'>
        <div className='row align-items-center'>
          <div className='col-12 col-md-6 col-lg-6'>
            <h2 className='fs-24 fs-lg-40 fs-xl-48 lh-30 lh-lg-40 lh-xl-45 text-grayscale--500 fw-400 mb-md-3'>
              <span className='fw-600'><span className='d-lg-block'>O que você vai</span> <span className='d-lg-block'>aprender nesta</span></span> websérie gratuita
            </h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-16 lh-lg-20 lh-xl-22 text-grayscale--400 mt-3' style={{ maxWidth: '460px' }}>
              Você vai descobrir como <span className='fw-600'>controlar seus gastos</span> e ainda usar recursos que irão te ajudar a economizar e <span className='fw-600'>ganhar dinheiro.</span>
            </p>
            <ScrollTo
              to='#financas-na-pratica'
              section='dobra_03'
              sectionName='O que você vai aprender nesta websérie gratuita'
              elementName='Inscreva-se agora'
            >
              <button className='btn btn-orange--extra btn--lg fs-14 fw-600 rounded-2 mx-auto text-white text-none mt-3'>Inscreva-se agora</button>
            </ScrollTo>
          </div>
          <Carousel className='col-12 col-md-6 col-lg-6 mt-5 mt-md-0 pl-xl-5'>
            <div className='pl-xl-5'>
              <DefaultCarousel
                sm={{ items: 2, partialVisibilityGutter: 10 }}
                md={{ items: 2, partialVisibilityGutter: 30 }}
                lg={{ items: 3, partialVisibilityGutter: 25 }}
                xl={{ items: 3, partialVisibilityGutter: 10 }}
              >
                {
                  OqueVoceVaiAprenderJSON.map((item: OqueVoceVaiAprenderProps, index: number) => (
                    <div
                      role='img'
                      aria-label={item.image_alt}
                      style={{ backgroundImage: `url(${item.image})` }}
                      key={item.title}
                      className='d-flex justify-content-center rounded-5 ml-3 pb-2 pb-xl-3 bg d-flex align-items-end cursor-pointer'
                      onMouseOver={() => {
                       setIsActive(true)
                       handleClick(index)
                      }}
                      onMouseOut={() => {
                        setIsActive(false)
                        handleClick(index)
                      }}
                    >
                      <div className={`content-text ${isActive && isIndex === index ? 'bg-hover' : ''}`}>
                        {isActive && isIndex === index
                        ? <p className='fs-14 fs-xl-16 lh-17 lh-md-16 lh-xl-20 text-white fw-700 px-3 mb-2 mb-md-0 description' dangerouslySetInnerHTML={{ __html: item.description }} />
                        : (
                          <div className='content'>
                            <p className='fs-14 fs-xl-18 lh-16 lh-xl-20 text-white fw-400 px-3 mb-2 mt-md-4 mt-lg-4'>Módulo {index + 1}</p>
                            <p className='fs-14 fs-xl-18 lh-17 lh-md-16 lh-xl-20 text-white fw-700 px-3 mb-2 mt-md-4' dangerouslySetInnerHTML={{ __html: item.title }} />
                          </div>
                          )
                       }
                      </div>
                    </div>
                  ))
                }
              </DefaultCarousel>
            </div>
          </Carousel>
        </div>
      </div>
    </Section>
  )
}

export default OqueVoceVaiAprender
