import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const Section = styled.section`
  h2 {
    font-family: 'Citrina';
  }
`

export const BlogCarousel = styled.div`
  .primary-tag, .secundary-tag {
    display: inline;
  }

  &.p-card {
    padding: 0;

    @media ${device.tablet} {
      padding-right: 24px;
    }
    @media ${device.desktopLG} {
      padding-right: 10.5px;
      padding-left: 10.5px
    }
  }

  article {
    display: grid;
    min-height: 350px;

    > div {
      border: 1px solid #DEDFE4;
      border-radius: 10px 10px 0 0;
      width: 288px;

      @media ${device.tablet} {
        height: 170px;
        width: auto;
      }

      @media ${device.desktopLG} {
        height: 140px;
        width: auto;
      }

      @media ${device.desktopXL} {
        height: 120px;
        width: auto;
      }
    }

    @media ${device.desktopLG} {
      min-height: 470px;
    }
  }

  .react-multi-carousel-track {
    padding: 30px 0 10px!important;
  }

  .title-post {
    font-family: 'Citrina';
    @media ${device.tablet} {
      letter-spacing: 0;
    }
    @media ${device.desktopLG} {
      letter-spacing: 1;
    }
  }

  .box-read-more{
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    align-items: center;
    > a {
      margin-right: 16px;
    }
  }
`
