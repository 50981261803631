import React from 'react'

// Components
import ImageWebp from 'src/components/ImageWebp'

// Style
import { BlogCarousel, Section } from './style'

// Hooks
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

type ItensProps = {
  image: string;
  primaryTag: string;
  secundaryTag: string;
  title: string;
  data: string;
  description: string;
  link: string;
  alt: string;
}

const DicasParaInvestirMelhor = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const arrayNumbers: number[] = [
    288, 216, 296, 360, 440,
  ]

  return (
    <Section
      id='dicas-para-investir-melhor'
      className='bg-white d-flex py-5'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 fs-lg-32 fs-xl-40 lh-30 lh-lg-40 lh-xl-50 fw-600 text-grayscale--500 text-md-center'>Conteúdos que podem te ajudar</h2>
            <p className='fs-14 fs-lg-16 fs-xl-18 lh-17 lh-lg-20 lh-xl-22 fw-400 text-grayscale--400 mt-3 text-md-center'>Os melhores conteúdos para te ajudar a investir com segurança e rentabilidade.</p>
          </div>
        </div>
        <div className='row mt-3'>
          <a
            href='https://blog.inter.co/como-comecar-a-investir/'
            target='_blank'
            rel='noreferrer'
            className='fs-14 lh-17 fw-400 text-orange--extra text-left mt-xl-3 col-12 col-md-4 mb-4 d-flex justify-content-center'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_07',
                section_name: 'null',
                element_action: 'click button',
                element_name: 'Como começar a investir do zero: melhor guia para iniciantes',
                redirect_url: 'https://blog.inter.co/como-comecar-a-investir/',
              })
            }}
          >
            <BlogCarousel className='p-card mt-md-3'>
              <article className='col-12 px-0'>
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/blog1-financas/image.webp'
                  altDescription='Pessoa olhando o saldo no smartphone com a tela laranja.'
                  arrayNumbers={arrayNumbers}
                />
                <div className='col-12 pt-3 px-4 px-md-3 px-lg-4 mt-n5 bg-white'>
                  <h3 className='fs-20 lh-25 fw-600 mt-3 mb-2 mt-md-2 mt-xl-2 title-post text-grayscale--500'>
                    Como começar a investir do zero: melhor guia para iniciantes
                  </h3>
                  <div className='box-read-more'>
                    <a
                      href='https://blog.inter.co/como-comecar-a-investir/'
                      target='_blank' rel='noreferrer'
                    >Leia mais
                    </a>
                    <OrangeIcon
                      size='MD'
                      color='#FF7A00'
                      icon='arrow-right'
                    />
                  </div>
                </div>
              </article>
            </BlogCarousel>
          </a>
          <a
            href='https://blog.inter.co/como-juntar-dinheiro-desafio-52-semanas/'
            target='_blank'
            rel='noreferrer'
            className='fs-14 lh-17 fw-400 text-orange--extra text-left mt-xl-3 col-12 col-md-4 mb-4 d-flex justify-content-center'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_07',
                section_name: 'null',
                element_action: 'click button',
                element_name: 'Como juntar dinheiro com o desafio 52 semanas + Planilha Gratuita',
                redirect_url: 'https://blog.inter.co/como-juntar-dinheiro-desafio-52-semanas/',
              })
            }}
          >
            <BlogCarousel className='p-card mt-md-3'>
              <article className='col-12 px-0'>
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/blog2-financas/image.webp'
                  altDescription='Mãos segurando smartphone na cor cinza.'
                  arrayNumbers={arrayNumbers}
                />
                <div className='col-12 pt-3 px-4 px-md-3 px-lg-4 mt-n5 bg-white'>
                  <h3 className='fs-20 lh-25 fw-600 mt-3 mb-2 mt-md-2 mt-xl-2 title-post text-grayscale--500'>
                    Como juntar dinheiro com o desafio 52 semanas + Planilha Gratuita
                  </h3>
                  <div className='box-read-more'>
                    <a
                      href='https://blog.inter.co/como-juntar-dinheiro-desafio-52-semanas/'
                      target='_blank' rel='noreferrer'
                    >Leia mais
                    </a>
                    <OrangeIcon
                      size='MD'
                      color='#FF7A00'
                      icon='arrow-right'
                    />
                  </div>
                </div>
              </article>
            </BlogCarousel>
          </a>
          <a
            href='https://blog.inter.co/bussola-planejamento-financeiro-pessoal/'
            target='_blank'
            rel='noreferrer'
            className='fs-14 lh-17 fw-400 text-orange--extra text-left mt-xl-3 col-12 col-md-4 d-flex justify-content-center'
            onClick={() => {
              sendDatalayerEvent({
                section: 'dobra_07',
                section_name: 'null',
                element_action: 'click button',
                element_name: 'Bússola: o jeito Inter de fazer seu planejamento financeiro pessoal',
                redirect_url: 'https://blog.inter.co/bussola-planejamento-financeiro-pessoal/',
              })
            }}
          >
            <BlogCarousel className='p-card mt-md-3'>
              <article className='col-12 px-0'>
                <ImageWebp
                  pathSrc='https://central-imagens.bancointer.com.br/images-without-small-versions/blog3-financas/image.webp'
                  altDescription='Mãos segurando o smartphone.'
                  arrayNumbers={arrayNumbers}
                />
                <div className='col-12 pt-3 px-4 px-md-3 px-lg-4 mt-n5 bg-white'>
                  <h3 className='fs-20 lh-25 fw-600 mt-3 mb-2 mt-md-2 mt-xl-2 title-post text-grayscale--500'>
                    Bússola: o jeito Inter de fazer seu planejamento financeiro pessoal
                  </h3>
                  <div className='box-read-more'>
                    <a
                      href='https://blog.inter.co/bussola-planejamento-financeiro-pessoal/'
                      target='_blank' rel='noreferrer'
                    >Leia mais
                    </a>
                    <OrangeIcon
                      size='MD'
                      color='#FF7A00'
                      icon='arrow-right'
                    />
                  </div>
                </div>

              </article>
            </BlogCarousel>
          </a>
        </div>
      </div>
    </Section>
  )
}

export default DicasParaInvestirMelhor
