import React, { MouseEvent } from 'react'

import * as S from './style'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'
import errorImg from '../../../../assets/images/form-error-image.png'
import successImg from '../../../../assets/images/form-success-image.png'
import Link from 'src/components/GatsbyLinkWrapper/index'

type ModalAlertProps = {
  onCloseIcon: (e: MouseEvent<HTMLDivElement | HTMLButtonElement>) => void;
  onClose: () => void;
  type: 'success' | 'error' | undefined;
}

export const ModalAlert = ({ onCloseIcon, onClose, type }: ModalAlertProps) => (
  <S.CardModal>
    <S.Close
      title='Fechar'
      onClick={(e: MouseEvent<HTMLDivElement>) => onCloseIcon(e)}
    >
      <OrangeIcon size='MD' color='#FF7A00' icon='exit' />
    </S.Close>

    <S.ContentText>
      <img src={type === 'success' ? successImg : errorImg } alt='Imagem de erro' />
      <h3 className='fs-24 lh-30 text-white fw-600 mt-3'>
        {type === 'success' && 'Cadastro realizado com sucesso!'}
        {type === 'error' && 'Houve um erro por aqui'}
      </h3>
      <p className='mt-3 text-white fs-14 lh-16'>
        {type === 'success' && 'Parabéns, o seu link está liberado!'}
        {type === 'error' && 'Não foi possível criar o seu cadastro. Experimente voltar dentro de alguns instantes.'}
      </p>
    </S.ContentText>

    {type === 'success' && (
      <Link
        to='/financas-na-pratica/1'
        className='mt-5 btn btn-orange--extra btn--lg fs-14 fw-600 rounded-2 mx-auto text-white text-none mb-4'
        onClick={() => onClose()}
      >
        Assistir agora!
      </Link>
      )}
    {type === 'error' && (
      <button
        title='Clique aqui para voltar'
        className='btn btn-orange--extra btn--lg fs-14 fw-600 rounded-2 mx-auto text-white text-none mt-3 mt-md-4'
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          e.preventDefault()
          onClose()
        }}
      >
        Entendi
      </button>
      )}
  </S.CardModal>
)
