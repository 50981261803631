import styled from 'styled-components'
import { white } from 'src/styles/colors'
import { device } from 'src/styles/breakpoints'

export const Container = styled.div`
  .link {
    text-decoration: underline;
  }

  min-height: 410px;

`

export const Close = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
`

export const CardForm = styled.div`
  background-color: var(--gray500);
  padding: 24px;
  border-radius: 16px;
`

export const Label = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 16px;
  &:last-child{
    margin-bottom: 0px;
  }
`

export const CardModal = styled.div`
  background-color: var(--gray500);
  padding: 24px;
  border-radius: 16px;
`

export const ContentText = styled.div`
  text-align: center;

`
