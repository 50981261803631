import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
const PlayFinancas = 'https://central-imagens.bancointer.com.br/images-without-small-versions/pratica-video/image.webp'

export const Section = styled.section`

  img {
    width: 48px;
  }

  height: 536px;
  background-image: url(${PlayFinancas});
  background-size: contain;
  background-repeat: no-repeat;

  h2 {
    font-family: 'Citrina';
  }

  @media ${device.tablet} {
    height: 263px;
  }

  @media ${device.desktopLG} {
    height: 356px;
  }

  @media ${device.desktopXL} {
    height: 500px;
  }
`

export const Video = styled.div`
  height: 353px;
  img {
    @media ${device.desktopXL} {
      position: relative;
      right: 70px;
    }

    @media ${device.desktopXXXL} {
      position: relative;
      left: - 70%;
    }
  }
`

export const PlayVideo = styled.div`
  width: 50px;
  height: 80px;

  @media ${device.tablet} {
    left: -17px;
    position: relative;
  }

  @media ${device.desktopLG} {
    width: 70px;
  }

  @media ${device.desktopXL} {
    left: -149px;
    width: 300px;
    height: 100px;
  }
`
