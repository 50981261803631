import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import Inter from './sections/hero/assets/images/inter.png'
import pageContext from './pageContext.json'

import { Hero, ControlarSuasFinancas, VoceVaiAprender, ImpactandoAsFinancas, OrganizeSuasContas, Conteudos, Faq } from './sections/_index'

const InvestindoNaPratica = () => {
  return (
    <>
      <Layout pageContext={pageContext}>
        <div style={{ padding: '18px 42px' }}>
          <img className='logo-inter' alt='Logo Inter' src={Inter} />
        </div>
        <Hero />
        <ControlarSuasFinancas />
        <VoceVaiAprender />
        <ImpactandoAsFinancas />
        <OrganizeSuasContas />
        <Conteudos />
        <Faq />
      </Layout>
    </>
  )
}

export default InvestindoNaPratica
