import React from 'react'

// Style
import { Section } from './style'

const ImpactandoAsFinancas = () => {
  return (
    <Section
      role='img'
      aria-label='Imagens de comentários de redes sociais passando na tela'
    >
      <div className='paralax-front d-flex align-items-center'>
        <div className='container'>
          <div className='row align-items-center'>
            <div className='col-12'>
              <p className='fs-26 fs-md-20 fs-lg-24 fs-xl-27 lh-40 lh-md-25 lh-lg-30 lh-xl-34 fw-400 text-center mb-2'>
                Impactando as finanças de
              </p>
              <p className='fs-40 fs-md-24 fs-lg-32 fs-xl-55 lh-40 lh-md-30 lh-lg-40 lh-xl-69 fw-600 text-orange--extra text-center mb-0'>+31 milhões de pessoas</p>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ImpactandoAsFinancas
