import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Section = styled.section`
  h2 {
    font-family: 'Citrina';
  }

  .cont-bussola{
    img {
      width: 80%;
    }
  }

  @media ${device.mobile} {
    .cont-bussola{
      img {
        width: 100%;
      }
    }
  }
`
