import React from 'react'

import FinancasNaPratica from 'src/components/UI/Forms/FinancasNaPratica'

import { Section } from './style'

import Financas from './assets/images/financas-na-pratica.png'

const Hero = () => {
  return (
    <Section
      id='financas-na-pratica'
      role='img'
      aria-label='Pessoa simpática de óculos e camisa laranja organizando as finanças no laptop.'
    >
      <div className='container'>

        <div className='row'>
          <div className='col-12 col-md-6 col-lg-5 financas'>
            <div className='content-info'>
              <img className='mb-4' alt='Finanças na prática' src={Financas} />
              <h1 className='fw-600 lh-40 text-grayscale--500'>
                Finanças na prática: organize sua vida financeira
              </h1>
              <p className='fs-18 lh-22 text-grayscale--400'>
                Aprenda como organizar suas finanças e livrar-se das dívidas com aulas
                gratuitas e um bônus exclusivo!
              </p>
            </div>
          </div>
          <div className='col-12 col-md-6 col-lg-4 offset-lg-3'>
            <FinancasNaPratica />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Hero
