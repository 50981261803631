import React, { useState, useEffect, MouseEvent, ChangeEvent } from 'react'
import axios from 'axios'
import { useForm, UseFormMethods } from 'react-hook-form'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'
import AcceptTerms from 'src/components/AcceptTerms/FinancasNaPratica'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import 'react-phone-input-2/lib/style.css'

import { getParameterByName } from 'src/shared/helpers'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'

import { Container, CardForm, Label } from './style'
import { ModalAlert } from './modalAlert'
import { IDataLayerParams } from 'src/hooks/useDataLayer/typesUseDataLayer'

interface IFormValues {
  nome: string;
  email: string;
  CpfCnpj: string;
  aceite: boolean;
}

function FormFinancasNaPratica () {
  const [ accept, setAccept ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [ sendDatalayerEvent ] = useDataLayer()
  const { register, setValue, errors, handleSubmit }: UseFormMethods<IFormValues> = useForm<IFormValues>()

  const [ utmSource, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)

  const [ typeModal, setTypeModal ] = useState<'success'|'error'>()

  const infoDataLayer: IDataLayerParams = {
    section: 'dobra_01',
    section_name: 'Finanças na prática: a websérie da sua vida financeira',
    element_action: 'click button',
    element_name: 'Quero me cadastrar',
  }

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
  }, [])

  const sendForm = async (data: IFormValues) => {
    setLoading(true)

    const formData = {
      ...data,
      campanha: 'Financas na Pratica',
      conteudo01: utmSource || '',
      conteudo02: utmMedium || '',
      conteudo03: utmCampaign || '',
      descricao: 'home',
    }

    try {
      await axios.post(`${URLS.CONTACT_FORM_POST_V5}/lp-mktcloud-inter`, [ formData ], {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      setTypeModal('success')
      sendDatalayerEvent({
        ...infoDataLayer,
        step: 'success',
      })
    } catch (e) {
      setTypeModal('error')
      sendDatalayerEvent(infoDataLayer)
    } finally {
      setLoading(false)
    }
  }

  const handleCloseIcon = (e: MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    e.preventDefault()
    setTypeModal(undefined)
    sendDatalayerEvent({
      ...infoDataLayer,
       element_name: 'Fechar',
     })
  }

  const handleCloseModal = () => {
    setTypeModal(undefined)
    if (typeModal === 'error') {
      sendDatalayerEvent({
        ...infoDataLayer,
        element_name: 'Clique aqui',
      })
    } else if (typeModal === 'success') {
      sendDatalayerEvent({
        ...infoDataLayer,
        element_name: 'Assistir agora!',
        redirect_url: '/financas-na-pratica/1',
      })
    }
  }

  return (
    <Container>
      {
        typeModal !== undefined
        ? (<ModalAlert onClose={handleCloseModal} onCloseIcon={handleCloseIcon} type={typeModal} />)
        : (
          <CardForm>
            <form className='form--default' name='open_account' onSubmit={handleSubmit(sendForm)}>
              <div className='body-form'>
                <Label>
                  <label htmlFor='nome' className='fs-14 lh-16 fw-600 text-white'>Nome</label>
                  <input
                    ref={register({
                  required: 'Por favor, digite seu nome completo',
                  validate: {
                    isName: (value: string) => Validations.name(value) || 'Por favor, digite seu nome completo',
                  },
                })}
                    name='nome'
                    id='nome'
                    type='text'
                    maxLength={100}
                    placeholder='Digite seu nome completo'
                  />
                  {errors.nome && <p className='fs-12 text-red--base mb-0 text-right'>{errors.nome.message}</p>}
                </Label>
                <Label>
                  <label htmlFor='email' className='fs-14 lh-16 fw-600 text-white'>E-mail</label>
                  <input
                    ref={register({
                  required: 'Digite um e-mail válido',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: 'E-mail inválido',
                  },
                })}
                    name='email'
                    id='email'
                    type='text'
                    placeholder='Digite seu e-mail'
                  />
                  {errors.email && <p className='fs-12 text-red--base mb-0 text-right'>{errors.email.message}</p>}
                </Label>
                <Label>
                  <label htmlFor='CpfCnpj' className='fs-14 lh-16 fw-600 text-white'>CPF</label>
                  <input
                    ref={register({
                  required: 'Digite seu CPF',
                  validate: {
                    isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                  },
                })}
                    name='CpfCnpj'
                    id='CpfCnpj'
                    type='text'
                    placeholder='Digite seu CPF'
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('CpfCnpj', Masks.MaskCPF(event.currentTarget.value)) }
                  />
                  {errors.CpfCnpj &&
                    <p className='fs-12 text-red--base mb-0 text-right'>
                      {errors.CpfCnpj.message}
                    </p>
              }
                </Label>
                <div className='my-4'>
                  <AcceptTerms
                    accept={accept} setAccept={setAccept}
                    name='promocao-tardezinha-miami'
                  />
                </div>
                <div>
                  <button
                    type='submit' title='Enviar contato' disabled={!accept || loading}
                    className='btn btn-orange--extra fw-600 rounded-2 mx-auto text-white text-none'
                  >
                    {loading ? 'Enviando...' : 'Quero me cadastrar'}
                  </button>
                </div>
              </div>
            </form>

          </CardForm>
          )
      }
    </Container>
  )
}

export default FormFinancasNaPratica
