import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { gray } from 'src/styles/colors'

import Bg01 from './images/bg-back.png'
import Bg02 from './images/bg-front.png'

export const Section = styled.section`
  background: ${gray[400]};

  background: url(${Bg01});
  height: 250px;
  width: 100%;
  background-color: #efeeed;
  background-attachment: fixed;
  background-position: center;
  background-repeat: repeat;
  position: relative;
  overflow: hidden;
  background-size: 110%;

  @media ${device.tablet} {
    background-repeat: no-repeat;
    background-size: contain;
    height: 277px;
  }

  @media ${device.desktopLG} {
    height: 370px;
  }

  @media ${device.desktopXL} {
    height: 520px;
  }

  @media ${device.desktopXXXL} {
    height: 680px;
  }

  h2, p {
    font-family: 'Citrina';
  }

  .paralax-front {
    height: 250px;
    background: url(${Bg02});
    background-position: center top;
    background-repeat: repeat;
    background-size: 120%;

    @media ${device.tablet} {
      background-size: contain;
      background-repeat: no-repeat;
      height: 277px;
    }

    @media ${device.desktopLG} {
      height: 370px;
    }

    @media ${device.desktopXL} {
      height: 520px;
    }

    @media ${device.desktopXXXL} {
      height: 680px;
      background-size: cover;
    }
  }
`
