import React from 'react'

import OpenVideo from 'src/components/OpenVideo'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section, Video, PlayVideo } from './style'

const ControlarSuasFinancas = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section
      role='img'
      aria-label='Imagem de calculadora no smartphone.'
      className='py-5 bg-grayscale--500 d-flex align-items-end align-items-md-center'
    >
      <div className='container'>
        <div className='row align-items-md-center'>
          <Video className='col-12 col-md-6 d-flex align-items-center justify-content-center'>
            <OpenVideo
              link='https://www.youtube.com/embed/C3hXOL1eLnY?si=yOi21TInWm8EE39I&autoplay=1'
              onClick={() => sendDatalayerEvent({
                section: 'dobra_02',
                element_action: 'click video',
                element_name: 'Play',
                section_name: 'Sua chance de organizar as finanças é agora!',
              })}
            >
              <PlayVideo className='cursor-pointer' />
            </OpenVideo>
          </Video>
          <div className='col-12 col-md-6 col-lg-5 offset-lg-1'>
            <h2 className='fs-28 fs-lg-32 fs-xl-40 lh-30 lh-lg-48 fw-600 text-white mb-3 mb-md-5'>
              Sua chance de organizar as finanças é agora!
            </h2>
            <div className='row'>
              <div className='col-4'>
                <div className='text-center mb-3'>
                  <OrangeIcon size='LG' color='#FF7A00' icon='approve-or-not' />
                </div>
                <p className='fs-14 lh-16 lh-lg-19 text-white fw-400 text-center mb-0'>
                  <span className='d-block'>Organize seu</span> orçamento
                </p>
              </div>
              <div className='col-4'>
                <div className='text-center mb-3'>
                  <OrangeIcon size='LG' color='#FF7A00' icon='pig-increased' />
                </div>
                <p className='fs-14 lh-16 lh-lg-19 text-white fw-400 text-center mb-0'>
                  <span className='d-block'>Se livre das</span> dívidas
                </p>
              </div>
              <div className='col-4'>
                <div className='text-center mb-3'>
                  <OrangeIcon size='LG' color='#FF7A00' icon='prepayment-of-receivables' />
                </div>
                <p className='fs-14 lh-16 lh-lg-19 text-white fw-400 text-center mb-0'>
                  <span className='d-block'>Planeje suas</span> finanças
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default ControlarSuasFinancas
